var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-52 w-full bg-black bg-opacity-50"},[_c('div',{staticClass:"container"},[(_vm.sliders.length > 0)?_c('VueSlickCarousel',_vm._b({ref:"carousel",on:{"afterChange":_vm.handleAfterChange}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.sliders),function(slider){return _c('div',{key:slider.id,staticClass:"object-cover overflow-hidden cursor-pointer",on:{"click":function($event){return _vm.handleOpenSlider(slider)}}},[_c('div',{staticClass:"h-52 w-screen shadow-lg mx-auto",style:({
            backgroundImage: 'url(' + slider.link_foto + ')',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backdropFilter: 'blur(10px)',
          })})])}),0):_vm._e()],1),_c('detail-slider',{ref:"detailVoucher"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }