<template>
  <div class="h-52 w-full bg-black bg-opacity-50">
    <!-- <div class="absolute h-full w-full" :style="sliderStyle"></div> -->
    <div class="container">
      <VueSlickCarousel
        v-bind="settings"
        ref="carousel"
        @afterChange="handleAfterChange"
        v-if="sliders.length > 0"
      >
        <div
          v-for="slider in sliders"
          :key="slider.id"
          class="object-cover overflow-hidden cursor-pointer"
          @click="handleOpenSlider(slider)"
        >
          <div
            class="h-52 w-screen shadow-lg mx-auto"
            :style="{
              backgroundImage: 'url(' + slider.link_foto + ')',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backdropFilter: 'blur(10px)',
            }"
          ></div>
        </div>
      </VueSlickCarousel>
    </div>
    <detail-slider ref="detailVoucher"></detail-slider>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "CarouselSlider",
  components: {
    Carousel,
    Slide,
    VueSlickCarousel,
    DetailSlider: () => import("@/components/layout/DetailSlider.vue"),
  },
  data: () => ({
    sliders: [],
    selectedSlider: {},
    settings: {
      arrows: false,
      dots: false,
      infinite: true,
      slidesToShow: 1,
      //   centerMode: true,
      lazyLoad: "progressive",
      //   centerPadding: "100px",
      variableWidth: true,
      focusOnSelect: true,
      swipeToSlide: true,
    },
  }),
  methods: {
    handleAfterChange(slideIndex) {
      this.selectedSlider = this.sliders[slideIndex];
    },
    handleOpenSlider(slider) {
      this.$refs.detailVoucher.open({ data: slider });
    },
  },
  computed: {
    sliderStyle() {
      return {
        backgroundImage: "url(" + this.selectedSlider.link_foto + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        filter: "blur(6px)",
        opacity: 0.5,
      };
    },
  },
  async mounted() {
    this.$store.commit("setOverlayLoading", true);
    try {
      const res = await this.$http.get(
        `${this.$apiTripweWeb}/promo/list`
      );
      if (res.data.status == 20000) {
        this.sliders = res.data.data;
        if (this.sliders.length > 0) {
          this.selectedSlider = this.sliders[0];
          if (this.sliders.length == 1) {
            this.settings.slidesToShow = 3;
          } else {
            this.settings.slidesToShow = 1;
          }
        } else {
          this.selectedSlider = {};
        }
      }
    } catch (error) {
    } finally {
      this.$store.commit("setOverlayLoading", false);
    }
  },
};
</script>
