<template>
  <div>
    <Header />
    <CarouselSlider class="hidden sm:block" />
    <CarouselSliderMobile class="sm:hidden" />
    <div
      class="container item-center w-full p-4 text-sm mx-auto text-primary font-bold md:hidden"
    >
      <div
        class="border-2 border-yellow rounded-lg py-2 px-4 flex gap-4 justify-around"
      >
        <div class="flex" @click="$router.push({ name: 'TripwePay' })">
          <img :src="icons.tripwePay" class="w-10 h-10" />
          <div class="flex flex-col ml-2">
            <span>TripwePay</span>
            <span v-if="Object.keys($store.state.user).length != 0">{{
              saldoTwpay | toCurrency
            }}</span>
            <span v-else>LOGIN</span>
          </div>
        </div>
        <div class="border border-gray"></div>
        <div class="flex" @click="$router.push({ name: 'AccountVoucher' })">
          <img :src="icons.voucher" class="w-10 h-10" />
          <div class="flex flex-col ml-2">
            <span>My Voucher</span>
            <span>{{ parseInt($store.state.voucherCount, 10) }} Voucher</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container item-center w-full text-center p-4 text-base mx-auto">
      <div class="grid grid-cols-2 gap-4 p-2 md:grid-cols-4">
        <div class="col-span md:col-start-2 mx-auto">
          <router-link
            :to="{
              name: 'ListPaket', 
              params: {
                type: 'safari',
                category: 'water'
              }}"
          >
            <img
              class="object-fill h-16 w-16 md:h-28 md:w-28 mx-auto"
              :src="advantureIcons[0]"
            /><span class="font-bold">Seadoo Safari</span>
          </router-link>
        </div>
        <div class="col-span mx-auto">
          <router-link
            :to="{
              name: 'ListPaket', 
              params: {
                type: 'safari',
                category: 'land'
              }}"
          >
            <img
              class="object-fill h-16 w-16 md:h-28 md:w-28 mx-auto"
              :src="advantureIcons[1]"
            /><span class="font-bold">Canam Safari</span>
          </router-link>
        </div>
        <!-- <div class="col-span mx-auto">
          <router-link
            :to="{
              name: 'ListPaket', 
              params: {
                type: 'safari',
                category: 'sky'
              }}"
          >
            <img
              class="object-fill h-16 w-16 md:h-28 md:w-28 mx-auto"
              :src="advantureIcons[2]"
            /><span class="font-bold">Sky Adventure</span>
          </router-link>
        </div> -->
      </div>
      <div
        class="hidden sm:grid grid-cols-9 gap-4 justify-center w-full px-4 py-8"
      >
        <div class="w-full">
          <router-link
            :to="{
              name: 'TripwePay',
            }"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[0]"
            /><span class="font-bold">TripwePay</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link
            :to="{
              name: 'Account',
            }"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[1]"
            /><span class="font-bold">Account</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link
            :to="{
              name: 'Wishlist',
            }"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[2]"
            /><span class="font-bold">Wishlist</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link
            :to="{
              name: 'Invoice',
              params: {
                slug: 'process',
                trip: 'safari',
              },
            }"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[3]"
            /><span class="font-bold">Transaction</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link
            :to="{
              name: 'Destination',
            }"
          >
            <img
              class="cursor-pointer object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[4]"
            /><span class="font-bold">Destinations</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link
            :to="{
              name: 'Video',
            }"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[5]"
            /><span class="font-bold">Video</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link :to="{ name: 'NewsIndex' }">
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[6]"
            /><span class="font-bold">News</span>
          </router-link>
        </div>
        <div class="w-full">
          <router-link :to="{ name: 'SpecialOffers' }">
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[7]"
            /><span class="font-bold">Special For You</span>
          </router-link>
        </div>
        <div class="w-full">
          <img
            @click="$router.push({ name: 'Events' })"
            class="cursor-pointer object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
            :src="menuMoreIcons[9]"
          /><span class="font-bold">Event</span>
        </div>
      </div>
    </div>
    <div class="px-4 py-2 mb-12 sm:hidden">
      <VueSlickCarousel v-bind="settings3">
        <div class="px-3">
          <router-link
            :to="{
              name: 'TripwePay',
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[0]"
            /><span class="font-bold text-sm mt-1">TripwePay</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{
              name: 'Account',
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[1]"
            /><span class="font-bold text-sm mt-1">Account</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{
              name: 'Wishlist',
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[2]"
            /><span class="font-bold text-sm mt-1">Wishlist</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{
              name: 'Invoice',
              params: {
                slug: 'process',
                trip: 'safari',
              },
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[3]"
            /><span class="font-bold text-sm mt-1">Transaction</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{
              name: 'Destination',
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="cursor-pointer object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[4]"
            /><span class="font-bold text-sm mt-1">Destinations</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{
              name: 'Video',
            }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[5]"
            /><span class="font-bold text-sm mt-1">Video</span>
          </router-link>
        </div>
        <div class="px-3">
          <router-link
            :to="{ name: 'NewsIndex' }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[6]"
            /><span class="font-bold text-sm mt-1">News</span>
          </router-link>
        </div>
        <div class="px-3">
          <img
            @click="$router.push({ name: 'SpecialOffers' })"
            class="cursor-pointer object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
            :src="menuMoreIcons[7]"
          />
          <div class="w-full text-center">
            <span class="font-bold text-xs mt-1 text-center"
              >Special For You</span
            >
          </div>
        </div>
        <div class="px-3 flex flex-col items-center">
          <router-link
            :to="{ name: 'Events' }"
            class="flex flex-col items-center mx-auto"
          >
            <img
              class="object-fill h-10 w-10 lg:h-16 lg:w-16 2xl:h-20 2xl:w-20 mx-auto"
              :src="menuMoreIcons[9]"
            /><span class="font-bold text-sm mt-1">Event</span>
          </router-link>
        </div>
        <template #customPaging="page">
          <div class="custom-dot">
            <div class="hidden">{{ page }}</div>
          </div>
        </template>
      </VueSlickCarousel>
    </div>
    <div class="w-full bg-grey-3 h-5">&nbsp;</div>
    <div
      class="container items-center mx-auto text-center py-8 px-6"
      id="special-for-you"
    >
      <h1 class="text-xl font-extrabold">SPECIAL FOR YOU</h1>
      <div class="py-8">
        <VueSlickCarousel v-bind="settings2" v-if="specialForYou.length > 0">
          <div v-for="slider in specialForYou" :key="slider.nama">
            <div
              class="rounded-md overflow-hidden shadow-lg cursor-pointer sm:m-5 mx-1"
              @click="openSpecialOfferDetail(slider)"
            >
              <div>
                <img
                  :src="slider.galeri[0].foto"
                  :alt="slider.nama"
                  class="object-cover sm:h-40 h-24 w-full"
                />
              </div>
              <div
                class="sm:py-4 sm:px-4 bg-white leading-none sm:leading-normal py-1 h-20 sm:h-full"
              >
                <div
                  class="sm:text-base text-xs font-semibold text-gray-600 sm:h-14"
                >
                  {{ slider.nama }}
                </div>
                <p class="sm:mt-2 sm:text-lg text-sm font-bold text-primary">
                  {{ slider.detail[0].harga | toCurrency }}
                </p>
                <span class="sm:mt-4 sm:text-base text-xs text-gray-600">{{
                  slider.nama_toko
                }}</span>
              </div>
            </div>
          </div>
          <template #customPaging="page">
            <div class="custom-dot">
              <div class="hidden">{{ page }}</div>
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="w-full bg-grey-3 h-5">&nbsp;</div>
    <div
      class="container items-center mx-auto text-center py-8 px-6"
      id="destinations"
    >
      <h1 class="text-xl font-extrabold">DESTINATIONS</h1>
      <div class="py-8">
        <VueSlickCarousel v-bind="settings2" v-if="destinations.length > 0">
          <div v-for="sliderD in destinations" :key="sliderD.judul">
            <div
              @click="openDestinationDetailPage(sliderD)"
              class="rounded-md overflow-hidden shadow-lg cursor-pointer sm:m-5 mx-1"
            >
              <div>
                <img
                  :src="sliderD.link_foto"
                  :alt="sliderD.judul"
                  class="object-cover w-full sm:h-40 h-24"
                />
              </div>
              <div
                class="sm:py-4 sm:px-4 bg-white leading-none sm:leading-normal py-2 h-16 sm:h-full"
              >
                <span
                  class="sm:text-lg text-base font-semibold text-gray-600 truncate"
                >
                  {{ sliderD.judul }}
                </span>
                <p class="sm:text-base text-sm font-thin text-primary">
                  {{ sliderD.nama_wilayah }}
                </p>
              </div>
            </div>
          </div>
          <template #customPaging="page">
            <div class="custom-dot">
              <div class="hidden">{{ page }}</div>
            </div>
          </template>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="w-full bg-grey-3 h-5" v-if="Object.keys(selectedVideo) != 0">
      &nbsp;
    </div>
    <div class="bg-grey-3" id="videos" v-if="Object.keys(selectedVideo) != 0">
      <div class="container py-8 px-6 items-center text-center mx-auto">
        <div
          class="rounded-md overflow-hidden shadow-lg cursor-pointer w-full mx-auto"
        >
          <video
            :poster="selectedVideo.link_foto"
            controls
            class="object-contain w-full px-10"
            controlsList="nodownload"
            :key="selectedVideo.id"
            autoplay
          >
            <source :src="selectedVideo.link_video" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
    <div class="w-full bg-grey-3 h-5">&nbsp;</div>
    <div class="bg-black" id="videos-carousel">
      <div
        class="container py-8 px-6 items-center text-center mx-auto"
        v-if="videos.length > 0"
      >
        <VueSlickCarousel v-bind="settings" ref="mNav" :asNavFor="$refs.mMain">
          <div
            v-for="sliderV in videos"
            :key="sliderV.judul"
            class="rounded-md overflow-hidden shadow-lg w-full mx-auto relative"
          >
            <!-- <video :poster="sliderV.link_foto" controls class="object-contain w-full px-10" controlsList="nodownload">
                            <source :src="sliderV.link_video" type="video/mp4" />
                        </video> -->
            <img
              :src="sliderV.link_foto"
              :alt="sliderV.judul"
              class="object-cover h-52 w-full"
            />
            <span
              class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white cursor-pointer"
              @click="togglePlayVideo(sliderV)"
            >
              <font-awesome-icon
                icon="fa-solid fa-play"
                size="3x"
                v-if="selectedVideo.id != sliderV.id"
              />
              <font-awesome-icon icon="fa-solid fa-xmark" size="3x" v-else />
            </span>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
    <div class="w-full h-5">&nbsp;</div>
    <div class="container py-8 px-6 item-center mx-auto" id="news">
      <h1 class="text-2xl font-extrabold text-center">NEWS</h1>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 p-4 py-8">
        <div
          v-for="sliderN in news"
          :key="sliderN.judul"
          class="col-span overflow-hidden"
        >
          <div class="relative">
            <img
              :src="sliderN.link_foto"
              :alt="sliderN.judul"
              class="object-cover h-64 w-full"
            />
            <div
              class="absolute bottom-0 left-0 py-4 px-4 text-white bg-gray-800 bg-opacity-25 w-full"
            >
              <span class="my-2 text-sm">{{ sliderN.tanggal }}</span>
              <h3 class="text-xl break-all font-bold truncate">
                {{ sliderN.judul }}
              </h3>
              <span
                class="mt-4 text-sm hover:underline cursor-pointer"
                @click="openDetailNews(sliderN)"
                >Read More</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <router-link
          to="/news"
          tag="button"
          class="rounded-xl bg-primary text-white text-xl font-bold py-2 px-8"
          >SEE MORE</router-link
        >
      </div>
    </div>
    <div
      class="container py-8 px-6 item-center mx-auto"
      id="news-carousel"
      v-show="false"
    >
      <div class="py-8">
        <carousel
          :perPage="3"
          :scrollPerPage="true"
          :navigationEnabled="true"
          :autoplay="false"
          :paginationEnabled="false"
          class="grid justify-items-center item-center"
        >
          <slide
            v-for="sliderM in moreNews"
            :key="sliderM.judul"
            class="w-full"
          >
            <div
              @click="openDetailNews(sliderM)"
              class="relative max-w-sm xl:max-w-md rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer m-5"
            >
              <div>
                <img
                  :src="sliderM.link_foto"
                  :alt="sliderM.judul"
                  class="object-cover h-40 w-full"
                />
              </div>
              <div class="py-4 px-4 bg-white">
                <span class="my-2 text-sm text-gray-600">{{
                  sliderM.tanggal
                }}</span>
                <h3 class="text-md font-semibold text-gray-600 truncate">
                  {{ sliderM.judul }}
                </h3>
                <span class="mt-4 text-sm text-gray-600">Read More...</span>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
    <Footer />
    <modal-banner  ref="modalBannerRef"></modal-banner>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import CarouselSlider from "../components/CarouselSlider";
import { Carousel, Slide } from "vue-carousel";
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { Slugify } from "../utils";
import CarouselSliderMobile from "../components/CarouselSliderMobile.vue";
import ModalBanner from "../components/layout/ModalBanner.vue";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    CarouselSlider,
    Carousel,
    Slide,
    VueSlickCarousel,
    CarouselSliderMobile,
    ModalBanner,
  },
  data: () => ({
    icons: {
      tripwePay: require("@/assets/icons/ic-pay.svg"),
      voucher: require("@/assets/icons/ic-voucher.svg"),
      ticket: require("@/assets/icons/ic-ticket.svg"),
    },
    banners: [],
    specialForYou: [],
    destinations: [],
    videos: [],
    selectedVideo: {},
    userPosition: {
      latitude: "",
      longitude: "",
    },
    moreNews: [],
    news: [],
    settings: {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 3,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
    settings2: {
      dots: false,
      arrows: true,
      infinite: true,
      centerMode: false,
      slidesToShow: 5,
      dotsClass: "custom-slick-dots",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 640,
          settings: {
            dots: true,
            arrows: false,
            centerMode: true,
            autoplay: true,
            centerPadding: "5%",
            slidesToShow: 2,
            speed: 1000,
            autoplaySpeed: 10000,
          },
        },
      ],
    },
    settings3: {
      dots: true,
      arrows: false,
      infinite: false,
      slidesToShow: 4,
      swipeToSlide: true,
      dotsClass: "custom-slick-dots",
    },
    advantureIcons: [
      require("@/assets/icons/icon-water-advanture.svg"),
      require("@/assets/icons/icon-land-advanture.svg"),
      require("@/assets/icons/icon-sky-advanture.svg"),
    ],
    menuMoreIcons: [
      require("@/assets/icons/tripwepay.svg"),
      require("@/assets/icons/account.svg"),
      require("@/assets/icons/wishlist.svg"),
      require("@/assets/icons/transaction.svg"),
      require("@/assets/icons/destination.svg"),
      require("@/assets/icons/video.svg"),
      require("@/assets/icons/news.svg"),
      require("@/assets/icons/special-for-you.svg"),
      require("@/assets/icons/insurance.svg"),
      require("@/assets/icons/event.svg"),
    ],
  }),
  mounted() {
  },
  computed: {
    saldoTwpay() {
      return this.$store.state.saldo.SaldoAktif;
    },
  },
  methods: {
    togglePlayVideo(slide) {
      if (
        Object.keys(this.selectedVideo) != 0 &&
        this.selectedVideo.id == slide.id
      ) {
        this.selectedVideo = {};
      } else {
        this.selectedVideo = slide;
      }
    },
    showPosition(position) {
      this.userPosition.latitude = position.coords.latitude;
      this.userPosition.longitude = position.coords.longitude;
      this.$store.commit("setCurrentLocation", this.userPosition);
      this.getSpecialForYou();
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, (_) => {
          this.getSpecialForYou();
        });
      } else {
        this.getSpecialForYou();
      }
    },
    async getSpecialForYou() {
      try {
        // this.$store.commit('setOverlayLoading', true);
        const res = await this.$http
          .post(`${this.$apiTripweWeb}/package/list/all`, {
            limit: "8",
            latitude: `${this.userPosition.latitude}`,
            longitude: `${this.userPosition.longitude}`,
            urut: 'jarak'
          });
          if(res.data.status != '200'){
            throw res.data.message;
          }
          this.specialForYou = res.data.data.item ?? [];
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        // this.$store.commit('setOverlayLoading', false);
      }
    },
    setPackage(pack) {
      this.$store.commit("setPackage", pack);
      var slug = null;
      var trip = null;
      if (pack.tipe == "0") {
        slug = "wateradventure";
      } else if (pack.tipe == "1") {
        slug = "landadventure";
        trip = "offroad";
      } else if (pack.tipe == "2") {
        slug = "skyadventure";
        trip = "onroad";
      }
      this.$router.push({
        name: "CheckoutPaket",
        params: { slug: slug, trip: trip },
      });
    },
    async getDestinations() {
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http
          .get(`${this.$apiTripweWeb}/destination/list`, {
            params: {
              limit: "8",
            }
          });
          if(res.data.status != 200){
            throw res.data.message;
          }
          this.destinations = res.data.data.item;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async loadBanner() {
      if(this.$store.state.showPopupHome == false){
        return;
      }
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http
          .get(`${this.$apiTripweWeb}/popup/list`);
          this.banners = res.data.data ?? [];
          if(this.banners.length > 0){
            this.$refs.modalBannerRef.open(this.banners);
          }
      } catch (error) {
        
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async getVideos() {
      try {
        const res = await this.$http
          .get(`${this.$apiTripweWeb}/video/list`);
  
          if(res.data.status == 20000){
            this.videos = res.data.data.item;
          }
      } catch (error) {
        
      }
    },
    async getAllNews() {
      try {
        const res = await this.$http
          .get(`${this.$apiTripweWeb}/news/list`);
        const data = res.data.data.item;
        this.news = data.slice(0, 6);
        this.moreNews = data.slice(6, data.length);
      } catch (error) {
        
      }
    },
    convertSlug(str) {
      return Slugify(str);
    },
    openSpecialOfferDetail(pack) {
      this.$router.push({
        name: "PackageDetail",
        params: { slug: Slugify(pack.nama), pack },
      });
    },
    openDestinationDetailPage(destination) {
      this.$router.push({
        name: "DestinationDetail",
        params: { slug: Slugify(destination.judul), destination },
      });
    },
    openDetailNews(news) {
      this.$router.push({
        name: "DetailNews",
        params: { slug: Slugify(news.judul), news },
      });
    },
  },
  created() {
    this.getLocation();
    this.loadBanner();
    this.getDestinations();
    this.getVideos();
    this.getAllNews();
  },
};
</script>
