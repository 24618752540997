<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-y-auto">
      <div class="flex items-center justify-center">
        <VueSlickCarousel v-bind="settings2" v-if="banners.length > 0" class="w-full max-w-xl md:max-w-md 2xl:max-w-xl">
          <div v-for="(item, index) in banners" :key="index" class="flex flex-col gap-4 p-10 h-full items-center">
            <div @click="cancel" class="flex cursor-pointer text-white font-bold gap-2 justify-end items-center">
              <span>Close</span>
              <font-awesome-icon
                          icon="fa-solid fa-close"
                          class="text-gray"
                        />
            </div>
            <img :src="item.link_popup_home" alt="" class="w-full max-w-md">
            <button @click="takeAction(item)" class="bg-yellow w-full text-black hover:bg-yellow-darker disabled:bg-gray-200 disabled:text-gray-500 disabled:cursor-default font-semibold py-2 px-6 rounded-lg">
                {{ textButton(item) }}
            </button>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { Slugify } from '@/utils';
export default {
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      dialog: false,
      banners: [],
      settings2: {
        dots: false,
        centerPadding: "5%",
        arrows: true,
        centerMode: true,
        slidesToShow: 1,
      },
    };
  },

  methods: {
    open(banners) {
      this.banners = banners;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    textButton(item){
      if(item.status_direct_home == 'aktif'){
        if(item.kategori_direct == 'paket'){
          return 'Book Now';
        }else{
          return 'Show Package';
        }
      }
    },
    async takeAction(item) {
      if(item.kategori_direct == "paket"){
        if(item.jenis_direct_home == "rental"){
          this.$router.push({
            name: 'Rental',
            params: { id_wilayah: item.id_wilayah_rental, id_toko: item.id_toko_home }
          });
        }else{
          if(item.package != null && Object.keys(item.package).length > 0){
            this.$router.push({
              name: 'PackageDetail',
              params: { slug: Slugify(item.package.nama), pack:item.package }
            });
    
            this.resolvePromise(true);
            this.dialog = false;
          }
        }
      }else if(item.kategori_direct == "toko"){
        this.$router.push({
          name: 'ListPaket',
          params: { id_toko: item.id_toko_home }
        });
      }
    },
    cancel() {
      this.$store.commit("setShowPopupHome", false);
      this.resolvePromise(false);
      this.dialog = false;
    },
  },
};
</script>
